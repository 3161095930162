import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Home() {
  const [user] = useAuthState(auth)
  const navigate = useNavigate()

  if (user && !user.emailVerified) {
    navigate('/emailVerification')
  }

  return <LandingPage />
}

export function LandingPage() {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6'>
      <div className='text-center max-w-2xl'>
        <h1 className='text-4xl font-bold text-gray-800 mb-4'>Welcome to Our Shop</h1>
        <div className='flex gap-4 justify-center'>
          <a
            href="/products"
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-rb-purple bg-transparent rounded-md shadow-sm hover:bg-rb-purple hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            {t('browseProducts')}
          </a>

          <button
            type='button'
            className='inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-rb-purple border border-transparent rounded-md shadow-sm hover:bg-rb-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
            onClick={(e) => {
              e.preventDefault()
              navigate('/order-form')
            }}
          >
            {t('placeOrder')}
          </button>

        </div>
      </div>
    </div>
  )
}
