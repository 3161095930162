import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoAddCircleOutline, IoCloseOutline, IoRemoveCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useCartHook } from '../../base/useCartHook';
import { useOtherSettings } from '../../base/useDbHooks';
import { useCartStore } from '../../base/CartStore';
import toast from 'react-hot-toast';
import useUserData, { UserData } from '../../base/useUserData';

export const CartContent = forwardRef(function CartContent(props, ref): JSX.Element {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { otherSettings } = useOtherSettings();
  const { items, setQuantity } = useCartStore();
  const { submitOrder, taxAmountByRate, totalAmount } = useCartHook({});
  const initialUserData: UserData & { comments?: string } = {
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    deliveryMethod: 'delivery',
    userId: '',
    comments: '',
  };
  const { formData } = useUserData(initialUserData);
  const [isUserInfoSaved, setIsUserInfoSaved] = useState(false); // Track if user info is saved

  // Check if all required user info fields are filled
  const isUserInfoComplete =
    formData.firstName &&
    formData.lastName &&
    formData.address &&
    formData.phone &&
    formData.deliveryMethod;

  const handleCheckout = () => {
    if (otherSettings && isUserInfoSaved) {
      submitOrder(otherSettings);
      toast.success(t('toast_order_success'));
      navigate('/');
    } else if (!isUserInfoSaved) {
      toast.error(t('user_info_required') || 'Please save your user information first');
    }
  };

  useImperativeHandle(ref, () => ({ handleCheckout }));

  const handleProductClick = (productId: string) => {
    navigate(`/product/${productId}`);
  };

  const handleQuantityChange = (p: any, newQuantity: number) => {
    if (newQuantity >= 0) setQuantity(p, newQuantity);
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 max-w-4xl mx-auto">
      <div className="overflow-x-auto w-full">
        {/* Header */}
        <div className="hidden sm:grid grid-cols-[2fr_1fr_1fr_1fr_50px] bg-gradient-to-r from-gray-100 to-gray-200 p-4 rounded-t-lg font-semibold text-gray-700">
          <div>{t('product')}</div>
          <div className="text-right">{t('price')}</div>
          <div className="text-center">{t('quantity')}</div>
          <div className="text-right">{t('total')}</div>
          <div></div>
        </div>

        {/* Product Items */}
        {items?.map((p, index) => (
          <div key={index} className="border-b last:border-b-0 py-4 hover:bg-gray-50 transition">
            {/* Mobile Layout */}
            <div className="sm:hidden space-y-3">
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() => handleProductClick(p.productId)}
              >
                <img
                  src={p.thumbImage}
                  alt={i18n.language === 'en' ? p.nameEn : p.nameJp}
                  className="w-12 h-12 rounded-md object-cover"
                />
                <div>
                  <span className="font-semibold text-gray-800">
                    {i18n.language === 'en' ? p.nameEn : p.nameJp}
                  </span>
                  <div className="text-sm text-gray-500">{p.unit}</div>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <button
                    className="text-gray-500 hover:text-red-500 transition"
                    onClick={() => handleQuantityChange(p, p.quantity - 1)}
                  >
                    <IoRemoveCircleOutline size="1.5em" />
                  </button>
                  <span className="w-8 text-center font-medium">{p.quantity}</span>
                  <button
                    className="text-gray-500 hover:text-green-500 transition"
                    onClick={() => handleQuantityChange(p, p.quantity + 1)}
                  >
                    <IoAddCircleOutline size="1.5em" />
                  </button>
                </div>
                <div className="text-right">
                  <div className="text-sm text-gray-600">
                    ¥{new Intl.NumberFormat('ja-JP').format(p.price)}
                  </div>
                  <div className="font-semibold text-gray-800">
                    ¥{new Intl.NumberFormat('ja-JP').format(p.quantity * p.price)}
                  </div>
                </div>
                <button
                  className="text-red-500 hover:text-red-700 transition"
                  onClick={() => handleQuantityChange(p, 0)}
                >
                  <IoCloseOutline size="1.5em" />
                </button>
              </div>
            </div>

            {/* Desktop Layout */}
            <div className="hidden sm:grid grid-cols-[2fr_1fr_1fr_1fr_50px] items-center gap-3">
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() => handleProductClick(p.productId)}
              >
                <img
                  src={p.thumbImage}
                  alt={i18n.language === 'en' ? p.nameEn : p.nameJp}
                  className="w-16 h-16 rounded-md object-cover"
                />
                <div>
                  <span className="font-semibold text-gray-800">
                    {i18n.language === 'en' ? p.nameEn : p.nameJp}
                  </span>
                  <div className="text-sm text-gray-500">{p.unit}</div>
                </div>
              </div>
              <div className="text-right text-gray-600">
                ¥{new Intl.NumberFormat('ja-JP').format(p.price)}
              </div>
              <div className="flex justify-center items-center gap-2">
                <button
                  className="text-gray-500 hover:text-red-500 transition"
                  onClick={() => handleQuantityChange(p, p.quantity - 1)}
                >
                  <IoRemoveCircleOutline size="1.5em" />
                </button>
                <span className="w-8 text-center font-medium">{p.quantity}</span>
                <button
                  className="text-gray-500 hover:text-green-500 transition"
                  onClick={() => handleQuantityChange(p, p.quantity + 1)}
                >
                  <IoAddCircleOutline size="1.5em" />
                </button>
              </div>
              <div className="text-right font-semibold text-gray-800">
                ¥{new Intl.NumberFormat('ja-JP').format(p.quantity * p.price)}
              </div>
              <div className="text-center">
                <button
                  className="text-red-500 hover:text-red-700 transition"
                  onClick={() => handleQuantityChange(p, 0)}
                >
                  <IoCloseOutline size="1.5em" />
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Summary */}
        <div className="bg-gradient-to-r from-gray-50 to-gray-100 p-4 rounded-b-lg mt-4">
          {Array.from(taxAmountByRate.entries()).map(([taxRate, taxAmount]) => (
            <div key={taxRate} className="flex justify-between text-sm text-gray-600">
              <span>{t('tax')} {taxRate}%</span>
              <span>¥{new Intl.NumberFormat('ja-JP').format(taxAmount)}</span>
            </div>
          ))}
          <div className="flex justify-between text-lg font-semibold text-gray-800 mt-2">
            <span>{t('total')}</span>
            <span>¥{new Intl.NumberFormat('ja-JP').format(totalAmount)}</span>
          </div>
        </div>
      </div>

      <UserDataDisplay setIsUserInfoSaved={setIsUserInfoSaved} />
      <div className="text-right mt-6">
        <button
          className={`px-6 py-3 rounded-lg shadow-md transition transform ${
            isUserInfoSaved
              ? 'bg-gradient-to-r from-green-500 to-green-600 text-white hover:from-green-600 hover:to-green-700 hover:scale-105'
              : 'bg-gray-400 text-gray-200 cursor-not-allowed'
          }`}
          onClick={handleCheckout}
          disabled={!isUserInfoSaved}
        >
          {t('checkout')}
        </button>
        {!isUserInfoSaved && (
          <p className="text-red-500 text-sm mt-2">
            {t('user_info_required') || 'Please save your user information first'}
          </p>
        )}
      </div>
    </div>
  );
});

interface UserDataDisplayProps {
  setIsUserInfoSaved: (value: boolean) => void;
}

function UserDataDisplay({ setIsUserInfoSaved }: UserDataDisplayProps) {
  const { t } = useTranslation();
  const initialData: UserData & { comments?: string } = {
    firstName: '',
    lastName: '',
    address: '',
    phone: '',
    deliveryMethod: 'delivery',
    userId: '',
    comments: '',
  };
  const { formData, handleChange, handleSave } = useUserData(initialData);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Check if all required fields are filled before saving
    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.address ||
      !formData.phone ||
      !formData.deliveryMethod
    ) {
      toast.error(t('please_fill_all_required_fields') || 'Please fill all required fields');
      return;
    }
    handleSave();
    setIsUserInfoSaved(true); // Enable checkout button after saving
    toast.success(t('user_info_saved') || 'User information saved');
  };

  return (
    <div className="p-6 bg-gray-50 rounded-xl mt-6 border border-gray-200 shadow-sm">
      <h2 className="text-xl font-bold text-gray-800 mb-4">{t('user_info')}</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name Fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder={`${t('first_name')} *`}
            className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500 transition"
            required
          />
          <input
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder={`${t('last_name')} *`}
            className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500 transition"
            required
          />
        </div>

        {/* Address */}
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder={`${t('address')} *`}
          className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500 transition"
          required
        />

        {/* Phone */}
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder={`${t('phone_number')} *`}
          className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500 transition"
          required
        />

        {/* Delivery or Pickup Option */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            {t('delivery_method')} *
          </label>
          <div className="flex gap-6">
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="deliveryMethod"
                value="delivery"
                checked={formData.deliveryMethod === 'delivery'}
                onChange={handleChange}
                className="text-green-500 focus:ring-green-500 h-4 w-4"
                required
              />
              <span className="text-gray-700">{t('delivery')}</span>
            </label>
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="radio"
                name="deliveryMethod"
                value="pickup"
                checked={formData.deliveryMethod === 'pickup'}
                onChange={handleChange}
                className="text-green-500 focus:ring-green-500 h-4 w-4"
                required
              />
              <span className="text-gray-700">{t('pickup')}</span>
            </label>
          </div>
        </div>

        {/* Comments (Optional) */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            {t('comments')}
          </label>
          <textarea
            name="comments"
            value={formData.comments || ''}
            onChange={handleChange}
            placeholder={t('add_comments') || 'Add any additional comments...'}
            className="p-3 border border-gray-300 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-green-500 transition resize-y min-h-[100px]"
          />
        </div>

        {/* Save Button */}
        <button
          type="submit"
          className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg hover:bg-gray-800 transition"
        >
          {t('save')}
        </button>
      </form>
    </div>
  );
}
