import { useState, useEffect, useCallback } from 'react';

// Define allowed field names for better type safety
type FormField = keyof UserData;

export type UserData = {
  firstName: string;
  lastName: string;
  address: string;
  phone: string;
  deliveryMethod: 'delivery' | 'pickup';
  userId: string;
  comments?: string;
};

// Utility function to generate unique user ID
const generateUserId = (): string => {
  return `user_${Date.now()}_${Math.floor(Math.random() * 10000)}`;
};

// Default values as a constant
const DEFAULT_USER_DATA: UserData = {
  firstName: '',
  lastName: '',
  address: '',
  phone: '',
  deliveryMethod: 'delivery',
  userId: '',
  comments: '',
};

const useUserData = (initialData?: Partial<UserData>) => {
  const [formData, setFormData] = useState<UserData>({
    ...DEFAULT_USER_DATA,
    ...initialData,
  });
  const [error, setError] = useState<string | null>(null);

  // Initialize data from localStorage
  useEffect(() => {
    try {
      const storedData = localStorage.getItem('orderFormUserData');
      let userId = localStorage.getItem('userId');

      if (!userId) {
        userId = generateUserId();
        localStorage.setItem('userId', userId);
      }

      const baseData = { ...DEFAULT_USER_DATA, userId };

      if (storedData) {
        const parsedData = JSON.parse(storedData) as UserData;
        setFormData({ ...baseData, ...parsedData });
      } else {
        setFormData(baseData);
      }
    } catch (err) {
      setError('Failed to initialize user data');
      console.error('Initialization error:', err);
    }
  }, []);

  // Handle input changes with proper typing
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setFormData((prev) => {
        const updatedData = { ...prev, [name]: value } as UserData;
        try {
          localStorage.setItem('orderFormUserData', JSON.stringify(updatedData));
          setError(null);
        } catch (err) {
          setError('Failed to save to localStorage');
          console.error('Storage error:', err);
        }
        return updatedData;
      });
    },
    []
  );

  // Save current form data explicitly
  const handleSave = useCallback(() => {
    try {
      localStorage.setItem('orderFormUserData', JSON.stringify(formData));
      setError(null);
      return true;
    } catch (err) {
      setError('Failed to save user data');
      console.error('Save error:', err);
      return false;
    }
  }, [formData]);

  // Reset form to initial/default state
  const resetFormData = useCallback(() => {
    try {
      const userId = localStorage.getItem('userId') || generateUserId();
      const resetData = { ...DEFAULT_USER_DATA, ...initialData, userId };
      setFormData(resetData);
      localStorage.setItem('orderFormUserData', JSON.stringify(resetData));
      localStorage.setItem('userId', userId);
      setError(null);
    } catch (err) {
      setError('Failed to reset form data');
      console.error('Reset error:', err);
    }
  }, [initialData]);

  // Get current user data
  const getUserData = useCallback((): UserData => {
    try {
      const storedData = localStorage.getItem('orderFormUserData');
      const userId = localStorage.getItem('userId') || formData.userId;
      
      if (storedData) {
        return { ...JSON.parse(storedData), userId };
      }
      return { ...DEFAULT_USER_DATA, ...initialData, userId };
    } catch (err) {
      setError('Failed to retrieve user data');
      console.error('Get data error:', err);
      return { ...DEFAULT_USER_DATA, userId: formData.userId };
    }
  }, [formData.userId, initialData]);

  // Update specific field
  const updateField = useCallback(
    (field: FormField, value: string) => {
      setFormData((prev) => {
        const updatedData = { ...prev, [field]: value };
        handleSave();
        return updatedData;
      });
    },
    [handleSave]
  );

  return {
    formData,
    error,
    handleChange,
    handleSave,
    resetFormData,
    getUserData,
    updateField,
    isDirty: JSON.stringify(formData) !== JSON.stringify(getUserData()),
  };
};

export default useUserData;