import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../base/Page';
import { useOrder, useOrderItems, useUser } from '../base/useDbHooks';
import { Order, ProductOrder } from '../model/Order';
import { format } from 'date-fns';

export default function OrderView() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { orderSnapshot } = useOrder(orderId ?? '');
  const { orderProductsSnapshot } = useOrderItems(orderId);
  const { user } = useUser();
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    if (orderSnapshot) {
      const orderVal = orderSnapshot.val() as Order;
      if (orderVal && orderVal.orderId === orderId && orderVal.userId === user?.userId) {
        setOrder(orderVal);
      }
    }
  }, [orderSnapshot, orderId, user]);

  const date = order?.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : '';

  return (
    <Page>
      <div className="flex flex-col bg-white rounded-xl shadow-lg p-6 max-w-3xl mx-auto">
        <div className="flex justify-between items-center bg-gray-100 rounded-t-lg p-4">
          <button
            onClick={() => navigate('/orders')}
            className="text-blue-600 hover:text-blue-800 underline text-sm font-medium"
          >
            {t('back')}
          </button>
          <h1 className="text-2xl font-semibold text-gray-800">{t('orderView')}</h1>
          <div className="w-20"></div> {/* Spacer */}
        </div>

        {order && (
          <div className="p-6">
            <div className="bg-gray-50 rounded-lg p-4 mb-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm text-gray-600">
                <div>
                  <span>{t('orderId')}:</span>{' '}
                  <span className="font-medium">{order.orderId}</span>
                </div>
                <div>
                  <span>{t('orderDate')}:</span>{' '}
                  <span className="font-medium">{date}</span>
                </div>
                <div>
                  <span>{t('orderTotal')}:</span>{' '}
                  <span className="font-bold text-gray-800">
                    ¥{new Intl.NumberFormat('ja-JP').format(order.totalAmount)}
                  </span>
                </div>
                <div>
                  <span>{t('orderStatus')}:</span>{' '}
                  <span className="font-medium">{order.orderStatus}</span>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {orderProductsSnapshot?.map((o: any, i: number) => {
                const orderProduct = o.val() as ProductOrder;
                return (
                  <div
                    key={'orderItem' + i}
                    className="flex items-center justify-between bg-gray-50 p-4 rounded-lg shadow-sm"
                  >
                    <div className="flex items-center gap-4">
                      <img
                        src={orderProduct.thumbImage}
                        alt={orderProduct.nameEn}
                        className="w-16 h-16 object-cover rounded"
                      />
                      <div>
                        <div className="text-lg font-medium text-gray-800">
                          {i18n.language === 'en' ? orderProduct.nameEn : orderProduct.nameJp}
                        </div>
                        <div className="text-sm text-gray-600">
                          {t('quantity')}: {orderProduct.quantity}
                        </div>
                      </div>
                    </div>
                    <div className="text-right">
                      <span className="font-bold text-gray-800">
                        ¥{new Intl.NumberFormat('ja-JP').format(orderProduct.quantity * orderProduct.price)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </Page>
  );
}
