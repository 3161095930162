import { createContext } from 'react'
import { Category } from '../model/Category'
import { Product } from '../model/Product'
import { User } from '../model/User'
import { User as AuthUser } from 'firebase/auth'
import { DeliveryTimeslots, PickupTimeslots } from '../model/Settings'
import { UserData } from './useUserData'

export const DBContext = createContext<{
  // authUser?: AuthUser | null
  user?: UserData
  // cart: DataSnapshot[]
  categories: Category[]
  products: Product[]
  selectedTimeslot: DeliveryTimeslots | PickupTimeslots | undefined
  setSelectedTimeslot: (timeslot: DeliveryTimeslots | PickupTimeslots | undefined) => void
}>({
  // authUser: undefined,
  user: undefined,
  // cart: [],
  categories: [],
  products: [],
  selectedTimeslot: undefined,
  setSelectedTimeslot: () => {
    throw new Error('setSelectedTimeslot() not implemented')
  },
})
