import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Page } from '../../base/Page'
import { Product, ProductPricing } from '../../model/Product'
import { useDisplayCategories, useProductsByCategory } from '../../base/useDbHooks'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../firebase'
import { DataSnapshot } from 'firebase/database'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { useCartHook } from '../../base/useCartHook'
import Cart from '../Cart'
import { useEffect, useState, useRef } from 'react'

export default function ProductsSimple() {
  const { t, i18n } = useTranslation()
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredProducts, setFilteredProducts] = useState(productsByCategory)
  const searchInputRef = useRef<HTMLInputElement>(null) // Ref to scroll to search input

  useEffect(() => {
    logEvent(
      analytics,
      'view_item_list',
      // eslint-disable-next-line camelcase
      { item_list_name: 'products' },
    )
  }, [])

  useEffect(() => {
    if (!productsByCategory || !searchTerm) {
      setFilteredProducts(productsByCategory)
      return
    }

    const filtered = Object.keys(productsByCategory).reduce((acc: any, category) => {
      const filteredCategoryProducts = productsByCategory[category].filter((product: Product) =>
        (i18n.language === 'en'
          ? product.productNameEn
          : product.productNameJp
        ).toLowerCase().includes(searchTerm.toLowerCase())
      )

      if (filteredCategoryProducts.length > 0) {
        acc[category] = filteredCategoryProducts
      }
      return acc
    }, {})

    setFilteredProducts(filtered)
  }, [searchTerm, productsByCategory, i18n.language])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  // Function to scroll to the search input
  const handleSearchButtonClick = () => {
    if (searchInputRef.current) {
      searchInputRef.current.scrollIntoView({ behavior: 'smooth' })
      searchInputRef.current.focus() // Focus the input for immediate typing
    }
  }

  return (
    <Page>
      <div className='flex flex-col bg-gray-100 dark:bg-gray-900 w-screen lg:max-w-sm min-h-screen p-0'>
        {/* Header with Cart and Search */}
        <div className='flex flex-col px-2 py-1'>
          <div className='flex items-center justify-between'>
            <div className='text-2xl font-semibold'>Order Form</div>
          </div>
          <div className='mt-2 relative'>
            <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
              <svg
                className='h-5 w-5 text-gray-400 dark:text-gray-500'
                fill='none'
                stroke='currentColor'
                viewBox='0 0 24 24'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
                />
              </svg>
            </div>
            <input
              ref={searchInputRef} // Attach ref to the search input
              type='text'
              placeholder={t('searchProducts') || 'Search products...'}
              value={searchTerm}
              onChange={handleSearchChange}
              className='w-full pl-10 p-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-green-500'
            />
          </div>
        </div>

        {/* Category List */}
        <div className='pb-32'>
          {displayCategories
            ?.filter((category) => filteredProducts?.[category.category]?.length > 0)
            .map((category) => (
              <div
                key={category.category}
                className='flex flex-col gap-3 p-2 bg-white dark:bg-gray-800 rounded-lg shadow-md mb-6'
              >
                <h2 className='text-2xl font-semibold text-gray-900 dark:text-white text-center'>
                  {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
                </h2>
                <div className='flex flex-col gap-2'>
                  {filteredProducts?.[category.category]?.map((p: Product) => (
                    <ProductCardSimple key={p.productId} product={p} />
                  ))}
                </div>
              </div>
            ))}
          {Object.keys(filteredProducts || {}).length === 0 && searchTerm && (
            <div className='text-center p-4 text-gray-500 dark:text-gray-400'>
              {t('noProductsFound')}
            </div>
          )}
        </div>

        {/* Floating Buttons */}
        <div className='fixed z-50 bottom-10  right-8 flex flex-col gap-4'>
          {/* Floating Search Button */}
          <button
            onClick={handleSearchButtonClick}
            className='w-16 h-16 flex items-center justify-center bg-blue-500 text-white rounded-full hover:bg-blue-600 hover:scale-110 transition-all duration-300 focus:outline-none focus:ring focus:ring-blue-300'
            title={t('searchProducts') || 'Search Products'}
          >
            <svg
              className='h-6 w-6'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
          </button>

          {/* Floating Cart Button */}
          <div className='w-16 h-16 flex items-center justify-center hover:scale-110 transition-all duration-300'>
            <Cart />
          </div>
        </div>
      </div>
    </Page>
  )
}

export function ProductCardSimple({ product }: { product: Product }) {
  const [pricings, setPricings] = useState<ProductPricing[]>([])
  const { t, i18n } = useTranslation()
  const { productPricingSnapshots } = useProductPricing(product.productId)

  useEffect(() => {
    if (!productPricingSnapshots?.length) return

    // Filter and map in a single step for better efficiency
    const validPricings = productPricingSnapshots
      .map((snap) => snap.val() as ProductPricing)
      .filter((pricing) => pricing && Number(pricing.stock) > 0)

    setPricings(validPricings)
  }, [productPricingSnapshots])

  if (!pricings.length) {
    return <div className='product_results'></div>
  }

  return (
    <div className='w-full bg-rb-background  dark:bg-gray-800 rounded-lg shadow-md mb-4 p-1 hover:shadow-lg transition-shadow duration-200'>
      <Link to={`/product/${product.productId}`}>
        <div className='flex items-center gap-4'>
          <ProductImage product={product} />

          <div className='flex-1 text-lg font-semibold text-gray-900 dark:text-white'>
            {i18n.language === 'en' ? product.productNameEn : product.productNameJp}
          </div>
        </div>
      </Link>
      <div className='mt-2 w-full'>
        <Pricing product={product} productPricingSnapshots={productPricingSnapshots} />
      </div>
    </div>
  )
}

function ProductImage({ product }: { product: Product }) {
  const img = product?.images ? (Object.values(product.images)[0] as string) : undefined

  return (
    <div className='w-16 h-16 rounded overflow-hidden'>
      {img ? (
        <img className='w-full h-full object-cover' src={img} alt='Product' />
      ) : (
        <div className='w-full h-full flex items-center justify-center'></div>
      )}
    </div>
  )
}

function Pricing({
  product,
  productPricingSnapshots,
}: {
  product: Product
  productPricingSnapshots: DataSnapshot[]
}) {
  // Early return if product is missing or there are no valid pricing entries
  if (!product || !productPricingSnapshots?.length) {
    return null
  }

  // Filter out invalid pricing data upfront
  const validPricings = productPricingSnapshots
    .map((snapshot) => snapshot.val() as ProductPricing)
    .filter((pricing) => pricing && Number(pricing.stock) > 0)

  // If no valid pricings, return null
  if (validPricings.length === 0) {
    return null
  }

  return (
    <div className='overflow-x-auto rounded-lg shadow-md bg-white w-full'>
      <table className='w-full border-collapse'>
        <tbody className='divide-y'>
          {validPricings.map((pricing) => (
            <ProductPricingCard key={pricing.pricingId} product={product} pricing={pricing} />
          ))}
        </tbody>
      </table>
    </div>
  )
}

function ProductPricingCard({ product, pricing }: { product: Product; pricing: ProductPricing }) {
  const { t, i18n } = useTranslation()
  const { addToCart, setQuantity, cartProduct } = useCartHook({ product, productPricing: pricing })

  const quantity = cartProduct?.quantity ?? 0 // Default to 0 if cartProduct is undefined
  // const tax = (pricing.price * (product.taxRate ?? 0.08)).toFixed(2)
  const withOutTax = (pricing.price - pricing.price / (1 + (product.taxRate ?? 0.08))).toFixed(0)

  const handleDecrease = () => {
    if (cartProduct && quantity > 0) {
      setQuantity(cartProduct, quantity - 1)
    }
  }

  const handleIncrease = () => {
    if (cartProduct) {
      setQuantity(cartProduct, quantity + 1)
    } else {
      addToCart(product, pricing, 1)
    }
  }

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.target.value, 10)

    if (!Number.isNaN(newQuantity) && newQuantity >= 0) {
      addToCart(product, pricing, newQuantity)
    }
  }

  return (
    <tr className='border-b border-gray-200 text-gray-800 text-sm'>
      <td className='py-4 px-3'>
        <div>
          <span className='font-medium'>{pricing.unit}</span>
          {pricing.expireDate && (
            <p className='text-xs text-gray-500 mt-1'>Exp: {pricing.expireDate.toString()}</p>
          )}
        </div>
      </td>
      <td className='  flex flex-col'>
        <span className=' font-bold'>{withOutTax}円</span>{' '}
        <span className='text-gray-500 font-normal'>
          ({t('withTax')} {pricing.price}円)
        </span>
      </td>

      <td className='py-4 px-3'></td>
      <td className='py-4 px-3 text-right'>
        <div className='flex items-center justify-end gap-2'>
          <button
            type='button'
            onClick={handleDecrease}
            disabled={quantity <= 0}
            className={`w-9 h-9 flex items-center justify-center rounded-full transition focus:outline-none 
              ${quantity > 0
                ? 'bg-red-400 hover:bg-red-500 text-white'
                : 'bg-gray-300 text-gray-600 cursor-not-allowed'
              }`}
          >
            &minus;
          </button>

          <input
            type='number'
            id='Quantity'
            value={quantity}
            onChange={handleQuantityChange}
            className='w-12 h-9 text-center border border-gray-300 rounded-md bg-gray-50 focus:ring focus:ring-green-300 focus:border-green-500 sm:text-sm'
            min={0}
          />

          <button
            type='button'
            onClick={handleIncrease}
            className='w-9 h-9 flex items-center justify-center bg-green-500 text-white rounded-full transition hover:bg-green-600 focus:outline-none focus:ring focus:ring-green-300'
          >
            +
          </button>
        </div>
      </td>
    </tr>
  )
}
