import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Product } from '../../model/Product'

export function ProductCard({ product }: { product: Product }) {
  const { t, i18n } = useTranslation()

  return (
    <div className='bg-white dark:bg-gray-800 rounded-lg shadow-md hover:shadow-lg transition-all overflow-hidden'>
      <Link to={`/product/${product.productId}`}>
        <ProductImage product={product} />
      </Link>

      <div className='p-4 flex flex-col justify-between h-full'>
        <h5 className='text-lg font-semibold text-gray-900 dark:text-white mb-2'>
          <Link to={`/product/${product.productId}`} className='hover:underline'>
            {i18n.language === 'en' ? product.productNameEn : product.productNameJp}
          </Link>
        </h5>

        <div className='flex justify-end'>
          <Link
            to={`/product/${product.productId}`}
            className='text-blue-600 dark:text-blue-400 hover:underline'
          >
            {t('view')}
          </Link>
        </div>
      </div>
    </div>
  )
}

function ProductImage({ product }: { product: Product }) {
  const img = product?.images ? Object.values(product.images)[0] : undefined

  return (
    <div className='w-full aspect-square bg-gray-200 dark:bg-gray-700 flex items-center justify-center'>
      {img ? (
        <img
          src={img as string}
          alt={product.productNameEn}
          className='w-full h-full object-cover'
        />
      ) : (
        <span className='text-gray-500'>No Image</span>
      )}
    </div>
  )
}
