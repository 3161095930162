import { equalTo, getDatabase, orderByChild, query, ref } from 'firebase/database'
import { useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useList, useListVals, useObject, useObjectVal } from 'react-firebase-hooks/database'
import { DB } from '../DB'
import { auth, firebaseApp } from '../firebase'
import { Category } from '../model/Category'
import { Product } from '../model/Product'
import {
  DeliveryCharge,
  DeliveryLocation,
  DeliveryTimeslots,
  OtherSettings,
  PickupTimeslots,
} from '../model/Settings'
import { User } from '../model/User'
import { DBContext } from './DbContext'
import useUserData from './useUserData'

const database = getDatabase(firebaseApp)

const defaultCategory = ['cakes']

export const useCategories = () => {
  const [categories] = useListVals<Category>(ref(database, DB.categories))
  return { categories: categories ?? [] }
}

export const useUser = () => {
  // const [authUser] = useAuthState(auth)
  // const [user] = useObjectVal<User>(ref(database, DB.users + '/' + authUser?.uid))
  const {formData: user} = useUserData()

  return { user }
}

export const useDeliveryTimeslots = () => {
  const [deliveryTimeslots] = useListVals<DeliveryTimeslots>(ref(database, DB.deliveryTimeslots))
  return {
    deliveryTimeslots: deliveryTimeslots?.map((p) => {
      p.type = 'delivery'
      return p
    }),
  }
}

export const usePickupTimeslots = () => {
  const [pickupTimeslots] = useListVals<PickupTimeslots>(ref(database, DB.pickupTimeslots))
  return {
    pickupTimeslots: pickupTimeslots?.map((p) => {
      p.type = 'pickup'
      return p
    }),
  }
}

export const useDeliveryCharges = () => {
  const [deliveryCharges] = useListVals<DeliveryCharge>(ref(database, DB.deliveryCharges))
  deliveryCharges?.sort((a, b) => a.range - b.range)
  return { deliveryCharges }
}

export const useDeliveryLocations = () => {
  const [deliveryLocations] = useListVals<DeliveryLocation>(ref(database, DB.deliveryLocations))
  return { deliveryLocations }
}

export const useOtherSettings = () => {
  const [otherSettings] = useObjectVal<OtherSettings>(ref(database, DB.settings))
  return { otherSettings }
}

export const useProducts = () => {
  const [snapshotsProduct, loading] = useListVals<Product>(ref(database, DB.products))
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (snapshotsProduct) {
      // const onlyStockProducts = snapshotsProduct.filter(
      //   (p) => p.pricing !== undefined && !!Object.keys(p.pricing),
      // )
      setProducts(snapshotsProduct)
    }
  }, [snapshotsProduct])

  return { products, loading }
}

export const useDisplayCategories = () => {
  const [displayCategories, setDisplayCategories] = useState<Category[]>()
  const { user } = useUser()
  const { categories } = useContext(DBContext)

  useEffect(() => {
    // let dispCategories = defaultCategory
    // if (user) {
    //   const userCategories = user?.categories ? Object.values(user.categories) : []
    //   if (userCategories) {
    //     dispCategories = [...userCategories, ...defaultCategory]
    //   }
    // }
    const filteredCategories = categories // ?.filter((c) => dispCategories.includes(c.category)) ?? []
    filteredCategories.sort((a, b) => a.displayOrder - b.displayOrder)
    setDisplayCategories(filteredCategories)
  }, [user])
  return { displayCategories }
}

export const useCategory = (categoryKey?: string) => {
  const { categories } = useContext(DBContext)
  const [category, setCategory] = useState<Category>()

  useEffect(() => {
    if (categoryKey && categories) {
      setCategory(categories.find((c) => c.category === categoryKey))
    }
  }, [categoryKey, categories])

  return { category }
}

export const useProductsByCategory = () => {
  const { products } = useContext(DBContext)
  const [productsByCategory, setProductsByCategory] = useState<any>([])

  useEffect(() => {
    if (products) {
      const groupByCategory = products.reduce((group: any, product) => {
        const { category } = product
        group[category] = group[category] ?? []
        group[category].push(product)
        return group
      }, {})
      setProductsByCategory(groupByCategory)
    }
  }, [products])

  return { productsByCategory }
}

export const useProductsByCategoryId = (category?: string) => {
  const { productsByCategory } = useProductsByCategory()
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (productsByCategory && category) {
      setProducts(productsByCategory[category])
    }
  }, [category, productsByCategory])

  return { products }
}

export const useProduct = (id?: string) => {
  const { products } = useContext(DBContext)
  const [product, setProduct] = useState<Product>()

  useEffect(() => {
    if (products && id) {
      setProduct(products.find((p) => p.productId === id))
    }
  }, [products, id])

  return { product }
}

export const useOrdersList = () => {
  // const { authUser } = useContext(DBContext)
  const {user} = useUser()

  const [ordersSnapshot] = useList(
    query(ref(database, DB.currentOrders), orderByChild('userId'), equalTo(user?.userId ?? '')),
  )
  return { ordersSnapshot }
}

export const useOrder = (id: string) => {
  const [orderSnapshot] = useObject(query(ref(database, DB.currentOrders + '/' + id)))

  return { orderSnapshot }
}

export const useOrderItems = (id?: string) => {
  const [orderProductsSnapshot] = useList(ref(database, DB.orderProducts + '/' + id))

  return {
    orderProductsSnapshot,
  }
}
