import { useForm } from 'react-hook-form'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { auth, firebaseApp } from '../firebase'
import * as Yup from 'yup'
import { InputText } from '../base/InputText'
import { Card } from '../base/Card'
import { Page } from '../base/Page'
import { Title } from '../base/Title'
import { useYupValidationResolver } from '../base/validateResolver'
import { useEffect } from 'react'
import { Button } from 'flowbite-react'
import { UserInfo } from '../model/User'
import { getDatabase, ref, serverTimestamp, set } from 'firebase/database'
import { DB } from '../DB'
import toast from 'react-hot-toast'
import { useDeliveryLocations, useUser } from '../base/useDbHooks'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('error.required'),
  lastName: Yup.string().required('error.required'),
  address: Yup.string().required('error.required'),
  location: Yup.string().required('error.required'),
  phoneNumber: Yup.string().required('error.required'),
})

const database = getDatabase(firebaseApp)

export default function Profile() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [authUser] = useAuthState(auth)
  const { user } = useUser()
  const { deliveryLocations } = useDeliveryLocations()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserInfo>({ resolver: useYupValidationResolver(formSchema) })

  const onSubmit = (value: UserInfo) => {
    value.createdDate = value.createdDate ?? serverTimestamp()
    value.updatedDate = serverTimestamp()
    value.id = authUser?.uid
    value.locationAddressEn = deliveryLocations?.find(
      (l) => l.location === value.location,
    )?.addressEn
    value.locationAddressJp = deliveryLocations?.find(
      (l) => l.location === value.location,
    )?.addressJp
    set(ref(database, DB.users + '/' + authUser?.uid + '/info'), value)
    toast.success(t('toast_profile_saved'))
    navigate('/')
  }

  useEffect(() => {
    if (user) {
      reset(user)
    }
  }, [user])

  useEffect(() => {
    if (!authUser) {
      navigate('/')
    }
  }, [authUser])

  return (
    <>
      <Page>
        <Card className='bg-rb-background p-2'>
          <div className='p-1 space-y-2 md:space-y-2 sm:p-1'>
            <Title>{t('profile')}</Title>
            {/* {signUpError?.code && (
              <Alert color='failure' icon={IoAlertOutline}>
                {t(signUpError?.code)}
              </Alert>
            )} */}
            <form className='flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-wrap gap-2'>
                <div>
                  <InputText
                    {...register('firstName')}
                    name={'firstName'}
                    label={t('firstName') ?? 'First Name'}
                    maxLength={20}
                    error={t(errors?.firstName?.message as string) ?? undefined}
                  />
                </div>
                <div>
                  <InputText
                    {...register('lastName')}
                    name={'lastName'}
                    label={t('lastName') ?? 'Last Name'}
                    maxLength={20}
                    error={t(errors?.lastName?.message as string) ?? undefined}
                  />
                </div>
              </div>
              <div>
                <div className='block text-sm font-semibold text-gray-900 dark:text-white pt-5'>
                  {t('address')}
                </div>
                <div className='flex flex-wrap gap-1 items-baseline'>
                  <select
                    {...register('location')}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10 w-full'
                  >
                    <option value=''>{t('selectLocation')}</option>
                    {/* {deliveryLocations?.map((location) => (
                      <option
                        key={location.location}
                        value={location.location}
                        selected={user?.info?.location === location.location}
                      >
                        {i18n.language === 'ja' ? (
                          <>{location.addressJp}</>
                        ) : (
                          <>{location.addressEn}</>
                        )}
                      </option>
                    ))} */}
                  </select>
                  <InputText
                    {...register('address')}
                    name={'address'}
                    label={t('houseno') ?? 'House No / Building (1-1-1)'}
                    maxLength={15}
                    error={t(errors?.address?.message as string) ?? undefined}
                  />
                </div>
              </div>

              <div className='w-52 pt-5'>
                <InputText
                  {...register('phoneNumber')}
                  name={'phoneNumber'}
                  label={t('phoneNumber') ?? 'Phone number'}
                  maxLength={11}
                  error={t(errors?.phoneNumber?.message as string) ?? undefined}
                />
              </div>
              <div className='w-52'>
                <Button type='submit'>{t('save')}</Button>
              </div>
            </form>
          </div>
        </Card>
      </Page>
    </>
  )
}
