import { Page } from '../../base/Page'
import { Carousel } from 'flowbite-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import parse from 'html-react-parser'
import { useCategory, useProduct } from '../../base/useDbHooks'
import Loading from '../../base/Loading'
import { Product, ProductPricing } from '../../model/Product'
import { useEffect, useMemo, useCallback } from 'react'
import { logEvent } from 'firebase/analytics'
import { analytics, auth } from '../../firebase'
import { useCartHook } from '../../base/useCartHook'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { useAuthState } from 'react-firebase-hooks/auth'
import { format } from 'date-fns'
import { BsWhatsapp, BsPhone } from 'react-icons/bs'

export default function ProductView() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()

  const { product } = useProduct(id)
  const { category } = useCategory(product?.category ?? '')

  useEffect(() => {
    if (product && category) {
      logEvent(analytics, 'view_item', {
        items: [
          {
            // eslint-disable-next-line camelcase
            item_id: id,
            // eslint-disable-next-line camelcase
            item_name: product.productNameEn,
            // eslint-disable-next-line camelcase
            item_category: category.category,
            // eslint-disable-next-line camelcase
            item_list_id: category.category,
            // eslint-disable-next-line camelcase
            item_list_name: category.nameEn,
          },
        ],
        productName: product.productNameEn,
      })
    }
  }, [product, category, id])

  if (!product) return <Loading />

  return (
    <Page>
      <div className='container sm:px-2 px-5 py-10 mx-auto bg-rb-background'>
        <div className='lg:w-4/5 mx-auto flex flex-wrap'>
          {/* Product Image Section */}
          <div className='w-96 md:w-1/2 h-52 md:h-80 sm:h-52 p-4'>
            <ProductImages product={product} />
          </div>

          {/* Product Details Section */}
          <div className='sm:w-full md:w-1/2 p-4'>
            <h2 className='text-sm title-font text-gray-500 tracking-widest'>
              {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
            </h2>
            <h1 className='text-gray-900 text-3xl title-font font-medium mb-1'>
              {i18n.language === 'en' ? product.productNameEn : product.productNameJp}
            </h1>

            <span className='leading-relaxed'>
              {i18n.language === 'en'
                ? product?.productDescriptionEn && parse(product?.productDescriptionEn)
                : product?.productDescriptionJp && parse(product?.productDescriptionJp)}
            </span>

            <Pricing product={product} />
            <OrderByMessage product={product} />
          </div>
        </div>
      </div>
    </Page>
  )
}

// Extracted Pricing Component
function Pricing({ product }: { product?: Product }) {
  // const [authUser] = useAuthState(auth)
  const { productPricingSnapshots } = useProductPricing(product?.productId)

  if (!product || !productPricingSnapshots) return null
  // if (!authUser && product?.isDirectOrder) return null

  return (
    <div className='flex flex-col gap-2'>
      {productPricingSnapshots.map((s, i) => {
        const pp = s.val() as ProductPricing
        console.log('pp', pp)
        if (pp.expireDate && new Date(pp.expireDate).getTime() < Date.now()) return null
        if (pp.stock === 0 || pp.stock === '0') return null

        return <PricingView key={i} product={product} pricing={pp} />
      })}
    </div>
  )
}

// Extracted PricingView Component
function PricingView({ product, pricing }: { product: Product; pricing: ProductPricing }) {
  const { t, i18n } = useTranslation()
  const { addToCart } = useCartHook({ product, productPricing: pricing })
  const expireDate = new Date(pricing.expireDate ?? '')
  const withOutTax = pricing.price - pricing.price / (1 + (product.taxRate ?? 0.08))

  return (
    <div className='flex items-baseline gap-2 justify-end'>
      <span className='font-medium text-md text-gray-900'>{pricing.unit}</span>
      <span className='title-font font-medium text-2xl text-gray-900'>
        {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(withOutTax)}
      </span>
      <span className='title-font font-normal text-gray-700'>
        {t('withTax')}
        {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(
          pricing.price,
        )}
      </span>
      {!product.isDirectOrder && Number(pricing.stock) > 0 && (
        <button
          className='flex text-white bg-rb-purple border-0 py-2 px-4 focus:outline-none hover:bg-rb-dark rounded'
          onClick={() => addToCart(product, pricing, 1)}
        >
          {t('add')}
        </button>
      )}

      {Number(pricing.stock) <= 0 && <div className='py-2 px-4 '>No stock</div>}
      {pricing.expireDate && (
        <span className='font-extralight text-sm text-gray-900'>
          Exp: {format(expireDate, 'PP')}
        </span>
      )}
    </div>
  )
}

// Extracted OrderByMessage Component
function OrderByMessage({ product }: { product?: Product }) {
  const { t, i18n } = useTranslation()

  if (!product?.isDirectOrder) return null

  return (
    <div className='flex gap-2 w-full justify-end'>
      {/* <a
        href={`https://wa.me/818035009515?text=${encodeURIComponent(
          t('orderMessage', {
            productName: i18n.language === 'en' ? product?.productNameEn : product?.productNameJp,
          }),
        )}`}
        target='_blank'
        rel='noopener noreferrer'
        className='flex underline underline-offset-4 border-0 py-2 px-4 focus:outline-none hover:bg-rb-purple hover:text-white rounded gap-1'
      >
        <BsWhatsapp size={'2em'} color='25D366' />
        {t('orderByWhatsapp')}
      </a> */}

      <a
        href={'tel:0835009515'}
        className='flex underline underline-offset-4 border-0 py-2 px-4 focus:outline-none hover:bg-rb-purple hover:text-white rounded gap-1'
      >
        <BsPhone size={'2em'} color='black' />
        {t('orderByPhone')}
      </a>
    </div>
  )
}

// Extracted ProductImages Component
function ProductImages({ product }: { product?: Product }) {
  if (!product || !product?.images) return null

  return (
    <Carousel leftControl=' ' rightControl=' '>
      {Object.values(product?.images).map((v: any) => {
        return v && <img key={v} src={v} alt='Product' />
      })}
    </Carousel>
  )
}
