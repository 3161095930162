import { IoCartOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useCartStore } from '../base/CartStore';
import { t } from 'i18next';
import { motion } from 'framer-motion';

export default function Cart({ showButton = true }: { showButton?: boolean }) {
  const { items } = useCartStore();
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-4">
      <motion.a
        href="/cart"
        whileHover={{ scale: 1.1 }}
        className="relative p-2 text-purple-600 hover:text-purple-700 transition"
        onClick={(e : any) => {
          e.preventDefault();
          navigate('/cart');
        }}
      >
        <IoCartOutline size="1.8em" />
        <span className="sr-only">Cart</span>
        <span className="absolute -top-1 -right-1 w-5 h-5 flex items-center justify-center text-xs font-bold text-white bg-red-500 rounded-full border-2 border-white">
          {items?.length ?? 0}
        </span>
      </motion.a>
      {showButton && (
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg shadow-md hover:from-purple-700 hover:to-blue-700 transition"
          onClick={(e: any) => {
            e.preventDefault();
            navigate('/cart');
          }}
        >
          {t('viewCart')}
        </motion.button>
      )}
    </div>
  );
}
