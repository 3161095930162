import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from '../base/Page';
import { useOrdersList } from '../base/useDbHooks';
import { Order } from '../model/Order';
import { format } from 'date-fns';

export default function OrdersList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ordersSnapshot } = useOrdersList();

  return (
    <Page>
      <div className="flex flex-col bg-white rounded-xl shadow-lg p-6 max-w-3xl mx-auto">
        <div className="bg-gray-100 rounded-t-lg p-4 text-center text-2xl font-semibold text-gray-800">
          {t('orders')}
        </div>
        <ul className="flex flex-col divide-y divide-gray-200">
          {ordersSnapshot?.map((o, i) => {
            const order = o.val() as Order;
            const date = order.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : '';
            return (
              <li
                key={'order' + i}
                className="flex flex-col p-4 hover:bg-gray-50 transition-colors duration-200"
              >
                <div className="grid grid-cols-1 gap-2 text-sm text-gray-600 sm:grid-cols-2">
                  <div className="flex justify-between">
                    <span>{t('orderId')}:</span>
                    <span className="font-medium">{order.orderId}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>{t('orderDate')}:</span>
                    <span className="font-medium">{date}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>{t('orderTotal')}:</span>
                    <span className="font-bold text-gray-800">
                      ¥{new Intl.NumberFormat('ja-JP').format(order.totalAmount)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span>{t('orderStatus')}:</span>
                    <span className="font-medium">{order.orderStatus}</span>
                  </div>
                </div>
                <button
                  onClick={() => navigate('/orders/' + order.orderId)}
                  className="mt-4 text-sm text-blue-600 hover:text-blue-800 underline font-medium transition-colors self-end"
                >
                  {t('view')}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </Page>
  );
}
