import { child, get, getDatabase, push, ref, serverTimestamp, set } from 'firebase/database'
import { useCallback, useContext, useEffect, useState } from 'react'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { Product, ProductPricing } from '../model/Product'
import { Order, ProductOrder } from '../model/Order'
import { DBContext } from './DbContext'
import { OtherSettings } from '../model/Settings'
import { useCartStore } from './CartStore'
import useUserData from './useUserData'

const database = getDatabase(firebaseApp)

export const useCartHook = ({
  product,
  productPricing,
}: {
  product?: Product
  productPricing?: ProductPricing
}) => {
  const {
    addToCart: add,
    clearCart,
    items = [],
    setQuantity,
    subTotalAmount,
    taxAmountByRate,
    totalAmount,
    totalTaxAmount,
  } = useCartStore()

  // const { authUser, user } = useContext(DBContext)
  const {formData: userData, getUserData} = useUserData()

  const [cartProduct, setCartProduct] = useState<ProductOrder | undefined>()

  // Track the cart product based on selected product & pricing
  useEffect(() => {
    if (!items || !product || !productPricing) return

    const existingCartProduct = items.find(
      (p) => p?.pricingId === productPricing.pricingId && p?.productId === product.productId,
    )

    if (existingCartProduct !== cartProduct) {
      setCartProduct(existingCartProduct)
    }
  }, [items, product, productPricing, cartProduct])

  /**
   * Add or update a product in the cart
   */
  const addToCart = useCallback(
    (product: Product, productPricing: ProductPricing, quantity = 1) => {
      if (!product || !productPricing) return

      let productOrder = items.find(
        (p) => p?.pricingId === productPricing.pricingId && p?.productId === product.productId,
      )

      if (!productOrder) {
        productOrder = {
          id: Math.random().toString(36).substring(2, 15), // Generate short UUID
          additionDate: new Date().toISOString(),
          productId: product.productId,
          pricingId: productPricing.pricingId,
          nameEn: product.productNameEn,
          nameJp: product.productNameJp,
          unit: productPricing.unit,
          price: productPricing.price,
          quantity,
          comments: '',
          thumbImage: product.images?.['0'] ?? '',
          taxRate: product.taxRate,
          userId: userData?.userId,
        } as ProductOrder

        add(productOrder)
      } else {
        setQuantity(productOrder, quantity)
      }
    },
    [items, userData, add, setQuantity],
  )

  /**
   * Submit an order
   */
  const submitOrder = useCallback(
    (otherSettings?: OtherSettings) => {
      const _userData = getUserData();
      console.log('submitOrder1', _userData);
      if (!_userData?.userId || items.length === 0 ) return

      const orderId = push(child(ref(database), DB.currentOrders)).key
      if (!orderId) return
      console.log('submitOrder2');

      const order: Order = {
        orderDate: serverTimestamp(),
        orderStatus: 'pending',
        orderId,
        userId: userData?.userId,
        invoiceId: '',
        invoiceDate: '',
        cancelledDate: '',
        cancelReason: '',
        subTotal: subTotalAmount,
        taxes: Array.from(taxAmountByRate, ([rate, amount]) => ({ rate, amount })),
        taxAmount: totalTaxAmount,
        deliveryCharge: 0,
        deliveryChargeTax: 0,
        deliveryChargeTaxAmount: 0,
        totalAmount,
        paidAmount: 0,
        paidMethod: '',
        balanceAmount: totalAmount,
        deliveryTimeslot: '',
        deliveryDate: '2020-12-12',
        deliveryAddress: `${_userData?.address ?? ''}`,
        deliveryName: `${_userData?.firstName ?? ''} ${_userData?.lastName ?? ''}`,
        deliveryPhone: _userData?.phone ?? '',
        deliveryOrPickupMethod: _userData?.deliveryMethod ?? 'delivery',
        pickupTimeslot: '',
        comments: _userData?.comments ?? '', // Updated to use userData.comments
      };
  
      set(ref(database, `${DB.currentOrders}/${orderId}`), order);

      // Move cart products to order products
      items.forEach((productOrder) => {
        productOrder.orderId = orderId
        productOrder.userId = userData?.userId
        productOrder.key = productOrder.id

        set(ref(database, `${DB.orderProducts}/${orderId}/${productOrder.id}`), productOrder)

        // Reduce product stock
        if (productOrder.productId) {
          const productRef = ref(
            database,
            `${DB.productPricing}/${productOrder.productId}/${productOrder.pricingId}`,
          )
          get(productRef).then((snapshot) => {
            const product = snapshot.val() as ProductPricing
            if (product) {
              set(productRef, { ...product, stock: Number(product.stock) - productOrder.quantity })
            }
          })
        }
      })

      clearCart()
    },
    [
      items,
      totalAmount,
      subTotalAmount,
      taxAmountByRate,
      totalTaxAmount,
      clearCart,
      userData,
    ],
  )

  return {
    addToCart,
    setQuantity,
    clearCart,
    submitOrder,
    subTotalAmount,
    totalTaxAmount,
    taxAmountByRate,
    totalAmount,
    cartProduct,
  }
}
