import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import { Page } from '../../base/Page'
import { ProductCard } from './ProductCard'
import { Product } from '../../model/Product'
import { useDisplayCategories, useProductsByCategory } from '../../base/useDbHooks'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../firebase'
import { useState, useEffect } from 'react'

export default function Products() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredProducts, setFilteredProducts] = useState(productsByCategory)

  useEffect(() => {
    logEvent(analytics, 'view_item_list', {
    // eslint-disable-next-line camelcase
      item_list_name: 'products',
    })
  }, [])

  // Filter products based on search term
  useEffect(() => {
    if (!productsByCategory || !searchTerm) {
      setFilteredProducts(productsByCategory)
      return
    }

    const filtered = Object.keys(productsByCategory).reduce((acc: any, category) => {
      const filteredCategoryProducts = productsByCategory[category].filter((product: Product) =>
        (i18n.language === 'en'
          ? product.productNameEn
          : product.productNameJp
        ).toLowerCase().includes(searchTerm.toLowerCase())
      )
      
      if (filteredCategoryProducts.length > 0) {
        acc[category] = filteredCategoryProducts
      }
      return acc
    }, {})

    setFilteredProducts(filtered)
  }, [searchTerm, productsByCategory, i18n.language])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <Page>
      <div className='flex flex-col w-full bg-gray-100 dark:bg-gray-900 p-6'>
        {/* Search Bar */}
        <div className='mb-6 relative'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <svg
              className='h-5 w-5 text-gray-400 dark:text-gray-500'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
          </div>
          <input
            type='text'
            placeholder={t('searchProducts') || 'Search products..'}
            value={searchTerm}
            onChange={handleSearchChange}
            className='w-full pl-10 p-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
        </div>

        {displayCategories?.map((category) => {
          const products = filteredProducts[category.category]
          if (!products) return null

          return (
            <div key={category.category} className='mb-8'>
              <div className='flex items-center justify-between mb-4 border-b pb-2 border-gray-300 dark:border-gray-700'>
                <h2 className='text-2xl font-semibold text-gray-900 dark:text-white'>
                  <Link to={`/products/${category.category}`} className='hover:underline'>
                    {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
                  </Link>
                </h2>
                <Link
                  to={`/products/${category.category}`}
                  className='text-blue-600 dark:text-blue-400 hover:underline'
                >
                  {t('viewAll')}
                </Link>
              </div>
              <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6'>
                {products.map((p: Product) => (
                  <ProductCard key={p.productId} product={p} />
                ))}
              </div>
            </div>
          )
        })}
        {Object.keys(filteredProducts || {}).length === 0 && searchTerm && (
          <div className='text-center p-4 text-gray-500 dark:text-gray-400'>
            {t('noProductsFound')}
          </div>
        )}
      </div>
    </Page>
  )
}
