import { useTranslation } from 'react-i18next';
import { IoCartOutline, IoArrowBackOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Page } from '../base/Page';
import { Styles } from '../base/Styles';
import { CartContent } from './ordering/CartContent';
import { useCartStore } from '../base/CartStore';
import { motion } from 'framer-motion';

export default function CartView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { items } = useCartStore();

  if (!items || items.length === 0) {
    return (
      <Page>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col items-center justify-center bg-white rounded-2xl shadow-lg p-8 text-center space-y-6 max-w-md mx-auto min-h-[400px]"
        >
          <motion.div
            animate={{ y: [-5, 5, -5] }}
            transition={{ repeat: Infinity, duration: 2 }}
            className="p-4 bg-gradient-to-br from-purple-50 to-blue-50 rounded-xl"
          >
            <IoCartOutline size="4em" className="text-purple-600" />
          </motion.div>
          <h3 className="text-2xl font-semibold text-gray-700">{t('emptyCart')}</h3>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => navigate('/')}
            className="bg-gradient-to-r from-purple-600 to-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:from-purple-700 hover:to-blue-700 transition flex items-center"
          >
            <IoArrowBackOutline className="mr-2" />
            {t('continueShopping')}
          </motion.button>
        </motion.div>
      </Page>
    );
  }

  return (
    <Page>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-white rounded-2xl shadow-lg p-6 max-w-4xl mx-auto"
      >
        <div className="flex items-center justify-between pb-4 border-b border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-3 bg-gradient-to-br from-purple-50 to-blue-50 rounded-xl">
              <IoCartOutline size="1.8em" className="text-purple-600" />
            </div>
            <h1 className="text-2xl font-bold text-gray-800">
              {t('yourCart')} <span className="text-purple-600">({items.length})</span>
            </h1>
          </div>
          <motion.button
            whileHover={{ x: -4 }}
            onClick={() => navigate(-1)}
            className="flex items-center text-purple-600 hover:text-purple-700 px-4 py-2 rounded-lg transition"
          >
            <IoArrowBackOutline className="mr-2" />
            {t('back')}
          </motion.button>
        </div>
        <CartContent />
      </motion.div>
    </Page>
  );
}
