import {
  DataSnapshot,
  getDatabase,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
  set,
} from 'firebase/database'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { ProductPricing } from '../model/Product'

const database = getDatabase(firebaseApp)

export const useProductPricing = (productId?: string) => {
  const productPricingSnapshot = useMemo(
    () => ref(database, `${DB.productPricing}/${productId}`),
    [productId],
  )

  const [productPricingSnapshots, setProductPricingSnapshots] = useState<DataSnapshot[]>([])
  const productPricingSnapshotsMap = useMemo(() => new Map<string | null, DataSnapshot>(), [])

  const updateSnapshots = useCallback(() => {
    setProductPricingSnapshots(
      [...productPricingSnapshotsMap.values()].sort(
        (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
      ),
    )
  }, [productPricingSnapshotsMap])

  useEffect(() => {
    const handleChildAdded = (snapshot: DataSnapshot) => {
      productPricingSnapshotsMap.set(snapshot.key, snapshot)
      updateSnapshots()
    }

    const handleChildChanged = (snapshot: DataSnapshot) => {
      productPricingSnapshotsMap.set(snapshot.key, snapshot)
      updateSnapshots()
    }

    const handleChildRemoved = (snapshot: DataSnapshot) => {
      productPricingSnapshotsMap.delete(snapshot.key)
      updateSnapshots()
    }

    onChildAdded(productPricingSnapshot, handleChildAdded)
    onChildChanged(productPricingSnapshot, handleChildChanged)
    onChildRemoved(productPricingSnapshot, handleChildRemoved)

    return () => {
      off(productPricingSnapshot, 'child_added', handleChildAdded)
      off(productPricingSnapshot, 'child_changed', handleChildChanged)
      off(productPricingSnapshot, 'child_removed', handleChildRemoved)
    }
  }, [productPricingSnapshot, updateSnapshots])

  const reduceStock = useCallback(
    (productPricing: ProductPricing, reduceBy = 1) => {
      set(productPricingSnapshot.ref, {
        ...productPricing,
        stock: productPricing.stock || 0 - reduceBy,
      })
    },
    [productPricingSnapshot],
  )

  const increaseStock = useCallback((productPricingSnapshot: DataSnapshot, increaseBy = 1) => {
    const productPricing = productPricingSnapshot.val()
    set(productPricingSnapshot.ref, { ...productPricing, stock: productPricing.stock + increaseBy })
  }, [])

  return { productPricingSnapshots, reduceStock, increaseStock }
}
