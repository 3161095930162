import { getDatabase, ref } from 'firebase/database'
import { Dropdown, Navbar } from 'flowbite-react'
import { useEffect } from 'react'
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth'
import { useObject } from 'react-firebase-hooks/database'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { DB } from '../../DB'
import { firebaseApp, auth, analytics } from '../../firebase'
import Cart from '../Cart'
import Language from './language'
import { setUserId } from 'firebase/analytics'

const database = getDatabase(firebaseApp)

export default function Header() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [user] = useAuthState(auth)
  const [signOut] = useSignOut(auth)
  const [snapshot, loading] = useObject(ref(database, DB.users + '/' + user?.uid + '/info'))

  useEffect(() => {
    if (user) {
      if (!user.emailVerified && pathname !== '/emailVerification') {
        navigate('/emailVerification')
      }
      if (!snapshot?.toJSON() && !loading) {
        navigate('/profile')
      }
      setUserId(analytics, user.uid)
    }
  }, [user, loading])

  return (
    <>
      <Navbar
        fluid={true}
        rounded={true}
        className='items-baseline sticky top-0 z-30 bg-rb-background'
      >
        <Navbar.Brand
          onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}
          href='/'
        >
          <span className='flex flex-row'>
            <img src='logo192.png' className='mr-3 h-6 sm:h-9' alt='Flowbite Logo' />
            <div className='flex flex-col'>
              <span className='self-center whitespace-nowrap text-xl font-semibold dark:text-white'>
                <span className='text-rb-purple'>Royal</span>{' '}
                <span className='text-rb-orange'>Bakes</span>
              </span>
              <span className='self-center whitespace-nowrap text-sm text-rb-purple dark:text-white'>
                Your Taste
              </span>
            </div>
          </span>
        </Navbar.Brand>
        <div className='flex md:gap-4 sm:gap-2  md:order-2'>
          <Language />
          {/* {user && <Cart />} */}
          <UserMenu />
          {/* {!user && (
            <a
              href='/'
              className='hidden md:block'
              onClick={(e) => {
                e.preventDefault()
                navigate('/signin')
              }}
            >
              <span className={Styles.button}>{t('signIn')}</span>
            </a>
          )} */}

          {/* {!user && (
            <a
              href='/'
              className='hidden md:block'
              onClick={(e) => {
                e.preventDefault()
                navigate('/signup')
              }}
            >
              <span className={Styles.buttonOutline}>{t('signUp')}</span>
            </a>
          )} */}
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          {/* <Menu /> */}
          <Navbar.Link
            href='/products'
            onClick={(e) => {
              e.preventDefault()
              navigate('/products')
            }}
          >
            {t('products')}
          </Navbar.Link>
          <Navbar.Link
            href='/order-form'
            onClick={(e) => {
              e.preventDefault()
              navigate('/order-form')
            }}
          >
            {t('orderForm')}
          </Navbar.Link>

          <Navbar.Link
            href='/orders'
            onClick={(e) => {
              e.preventDefault()
              navigate('/orders')
            }}
          >
            {t('orders')}
          </Navbar.Link>

          <Navbar.Link
            href='/about'
            onClick={(e) => {
              e.preventDefault()
              navigate('/about')
            }}
          >
            {t('about')}
          </Navbar.Link>
          <Cart showButton={false} />
        </Navbar.Collapse>
      </Navbar>
    </>
  )

  function UserMenu() {
    return (
      <>
        {user && (
          <Dropdown
            label={
              <>
                <button
                  id='dropdownAvatarNameButton'
                  data-dropdown-toggle='dropdownAvatarName'
                  className='flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white'
                  type='button'
                >
                  {user?.photoURL && (
                    <img className='w-8 h-8 rounded-full' src={user.photoURL} alt='user photo' />
                  )}

                  <div className='hidden sm:block'>{user?.displayName ?? user?.email}</div>
                  <svg
                    className='w-4 h-4 mx-1'
                    aria-hidden='true'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                </button>
              </>
            }
            size='sm'
            inline={true}
            pill={true}
            arrowIcon={false}
          >
            <Dropdown.Header>
              {user?.displayName && (
                <span className='block text-sm truncate'>{user?.displayName}</span>
              )}
              <span className='block text-sm font-medium truncate text-gray-300'>
                {user?.email}
              </span>
            </Dropdown.Header>
            <Dropdown.Item onClick={() => navigate('/orders')}>{t('orders')}</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate('/profile')}>{t('profile')}</Dropdown.Item>
            <Dropdown.Item onClick={signOut}>{t('signOut')}</Dropdown.Item>
          </Dropdown>
        )}
      </>
    )
  }
}
