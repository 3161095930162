import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Page } from '../../base/Page'
import { ProductCard } from './ProductCard'
import { useCategory, useProductsByCategoryId } from '../../base/useDbHooks'
import { useEffect, useState } from 'react'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../../firebase'

export default function ProductsList() {
  const { t, i18n } = useTranslation()
  const { category: catId } = useParams()
  const { category } = useCategory(catId)
  const { products } = useProductsByCategoryId(catId)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredProducts, setFilteredProducts] = useState(products)

  useEffect(() => {
    if (category) {
      logEvent(analytics, 'view_item_list', {
        // eslint-disable-next-line camelcase
        item_list_id: catId,
        // eslint-disable-next-line camelcase
        item_list_name: category.nameEn,
      })
    }
  }, [category, catId])

  // Filter products based on search term
  useEffect(() => {
    if (!products || !searchTerm) {
      setFilteredProducts(products)
      return
    }

    const filtered = products.filter((product) =>
      (i18n.language === 'en'
        ? product.productNameEn
        : product.productNameJp
      ).toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredProducts(filtered)
  }, [searchTerm, products, i18n.language])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  return (
    <Page>
      <div className='flex flex-col items-center w-full py-6 bg-white dark:bg-gray-900'>
        {/* Category Title */}
        <h2 className='text-3xl font-semibold text-gray-900 dark:text-white'>
          {i18n.language === 'en' ? category?.nameEn : category?.nameJp}
        </h2>
        {/* Search Bar */}
        <div className='mt-4 w-full max-w-md relative'>
          <div className='absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none'>
            <svg
              className='h-5 w-5 text-gray-400 dark:text-gray-500'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z'
              />
            </svg>
          </div>
          <input
            type='text'
            placeholder={t('searchProducts') || 'Search products...'}
            value={searchTerm}
            onChange={handleSearchChange}
            className='w-full pl-10 p-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-900 dark:text-white focus:outline-none focus:ring-2 focus:ring-blue-500'
          />
        </div>
      </div>

      <div className='container mx-auto p-4'>
        {filteredProducts?.length > 0 ? (
          <div className='grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6'>
            {filteredProducts.map((p) => (
              <ProductCard key={p.productId} product={p} />
            ))}
          </div>
        ) : (
          <div className='text-center text-gray-500 dark:text-gray-400 py-10'>
            {searchTerm ? t('noProductsFound') : 'No products available.'}
          </div>
        )}
      </div>
    </Page>
  )
}
